import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ChatInput from "./ChatInput";
import Logout from "./Logout";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { sendMessageRoute, recieveMessageRoute } from "../utils/APIRoutes";

import { useSelector, useDispatch } from "react-redux";
import { get_companyid } from "../Services/Actions/action";


export default function ChatContainer({ currentChat, socket, getcompany_id }) {
    const [messages, setMessages] = useState([]);
    const scrollRef = useRef();
    const [arrivalMessage, setArrivalMessage] = useState(null);
    const [responsemsg, Setgmessage] = useState(null);
    const getcompanyid = useSelector((state) => state.getcompany_id).counter;
     const [loading, setLoading] = useState(true);

    useEffect(async () => {

         //console.log(getcompanyid);

        // const data = await JSON.parse(
        //     localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
        // );

        //console.log(currentChat._id);
        //console.log(company_id);
        //console.log(data._id);

         try {
           
          socket.current.emit("current_users", currentChat._id);

             const response = await axios.post(
                 recieveMessageRoute,
                 {
                     from: getcompanyid._id,
                     //from: data._id,
                     // from: company_id,
                     to: currentChat._id,
                 },
                 { timeout: 2000 }
             );


               setMessages(response.data);
          
            } catch (error) {
              console.error("Error fetching data:", error);
        
             } finally {
              
             setLoading(false); // Set loading to false regardless of success or error
         }


        //console.log(currentChat._id);
        //console.log(response.data[0]);

        //console.log(response.data[0]['from_id']);
        // setMessages(response.data);
        //console.log(currentChat._id);

        // if (response.data[0]["from_id"]!== currentChat._id) {
        //        setMessages('');
        //  }

          

        // Setgmessage(response.data[0]["from_id"]);
        // if (response.data[0] === "undefined") {
        //      console.log('undefined hitted');
        //        setMessages("");
        // } else {
        //     console.log("not undefined hitted");
        //     setMessages(response.data);
        // }

        // }
    }, [currentChat]);

    useEffect(() => {
        //console.log(getcompanyid);

        const getCurrentChat = async () => {
            if (currentChat) {
                await getcompanyid._id;
                // await JSON.parse(
                //     localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
                // )._id;
            }
        };
        getCurrentChat();
    }, [currentChat]);

    const handleSendMsg = async (msg) => {
        // const data = await JSON.parse(
        //     localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
        // );

        //  console.log(currentChat._id);
        //  console.log(getcompanyid._id);

        socket.current.emit("send-msg", {
            to: currentChat._id,
            //from: data._id,
            from: getcompanyid._id,
            msg,
         });
         

        await axios.post(sendMessageRoute, {
            //from: data._id,
            from: getcompanyid._id,
            to: currentChat._id,
            message: msg,
        });

        const msgs = [...messages];
        msgs.push({ fromSelf: true, message: msg });
        
        setMessages(msgs);
    
    };

      useEffect(() => {
       
        // const data = JSON.parse(
        //               localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
        //         );

        //console.log(responsemsg);
        // if (responsemsg === currentChat._id){

         if (socket.current) {
             socket.current.on("msg-recieve", (msg) => {
                //console.log(currentChat._id);
                // const data = JSON.parse(
                //     localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
                // );
                //console.log(data._id);
                //console.log(currentChat);

                  setArrivalMessage({ fromSelf: false, message: msg });

             
              });
         
          }

        //}
    }, []);

    useEffect(() => {
        arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
    }, [arrivalMessage]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    return (
        <Container>
            {loading ? (
                <div className="loading">Loading...</div>
            ) : (
                <div>
                    <div className="chat-header">
                        <div className="user-details">
                            <div className="avatar">
                                {/* <img
              src={`data:image/svg+xml;base64,${currentChat.avatarImage}`}
              alt=""
            /> */}
                            </div>
                            <div className="username">
                                <h3>{currentChat.username}</h3>
                                {/* <h3>{getusername}</h3> */}
                            </div>
                        </div>
                        <Logout />
                    </div>
                    <div className="chat-messages">
                        {messages.map((message) => {
                            //console.log(message.message);

                            return (
                                <div ref={scrollRef} key={uuidv4()}>
                                    <div
                                        className={`message ${
                                            message.fromSelf
                                                ? "sended"
                                                : "recieved"
                                        }`}
                                    >
                                        <div className="content ">
                                            <p>{message.message}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            <ChatInput handleSendMsg={handleSendMsg} />
        </Container>
    );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 10% 80% 10%;
  gap: 0.1rem;
  overflow: hidden;
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-rows: 15% 70% 15%;
  }
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    .user-details {
      display: flex;
      align-items: center;
      gap: 1rem;
      .avatar {
        img {
          height: 3rem;
        }
      }
      .username {
        h3 {
          color: white;
        }
      }
    }
  }
  .chat-messages {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .message {
      display: flex;
      align-items: center;
      .content {
        max-width: 40%;
        overflow-wrap: break-word;
        padding: 1rem;
        font-size: 1.1rem;
        border-radius: 1rem;
        color: #d1d1d1;
        @media screen and (min-width: 720px) and (max-width: 1080px) {
          max-width: 70%;
        }
      }
    }
    .sended {
      justify-content: flex-end;
      .content {
        background-color: #4f04ff21;
      }
    }
    .recieved {
      justify-content: flex-start;
      .content {
        background-color: #9900ff20;
      }
    }
  }
`;
