import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SetAvatar from "./components/SetAvatar";
import Chat from "./pages/Chat";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Frontend_chat from "./pages/Frontend_chat";
// import UserChat from "./pages/UserChat";
import Chatclient  from "./pages/Chatclient";
import Expire from './pages/Expire';
//import Test from "./pages/Test";


export default function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/setAvatar" element={<SetAvatar />} />
              {/* <Route path="/:id" element={<Chat />} /> */}
              {/* <Route path="/frontend" element={<Frontend_chat />} /> */}
              {/* <Route path="/:id/:id" element={<UserChat />} /> */}
              <Route path="/:id/:id" element={<Chatclient />} />
              <Route path="/expire" element={<Expire />} />
              
          </Routes>
      </BrowserRouter>
  );
}
