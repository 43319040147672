import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ChatInput from "./ChatInput";
import Logout from "./Logout";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { sendMessageRoute, recieveMessageRoute } from "../utils/APIRoutes";

import { useSelector, useDispatch } from "react-redux";
import { get_companyid } from "../Services/Actions/action";
import $ from "jquery";
//import notificationSound from "../pages/notification.mp3";


export default function ChatContainer({
    currentChat,
    socket,
    getcompany_id,
    currentUser,
    getusername,
 
  }) {

    const [messages, setMessages] = useState([]);
    const scrollRef = useRef();
    const [arrivalMessage, setArrivalMessage] = useState(null);
    const [responsemsg, Setgmessage] = useState(null);
    const [loading_chat, setLoading] = useState(true);
    
      const url = window.location.pathname;
      const segment = url.split("/");
      const url2 = window.location.href;

      const split_seg = url2.split("?");

      const split0 = split_seg[0];
      const split1 = split_seg[1];

      
       // const audio = new Audio();
       //  const audioRef = useRef(new Audio(notificationSound));
     
       //  const [notificationPermission, setNotificationPermission] = useState(
       //      Notification.permission
       //  );


      //  useEffect(() => {
      //      if (notificationPermission !== "granted") {
      //          Notification.requestPermission().then((permission) => {
      //              setNotificationPermission(permission);
      //          });
      //      }
      //  }, [notificationPermission]);

    //const getcompanyid = useSelector((state) => state.getcompany_id).counter;

    useEffect(async () => {
        //console.log(getcompanyid);

        // const data = await JSON.parse(
        //     localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
        // );
        //console.log(currentChat._id);
        //console.log(company_id);
        //console.log(data._id);
           

           socket.current.emit("current_users", currentChat._id);

           //console.log(getcompany_id);
          //console.log(currentChat._id);

        const response = await axios.post(recieveMessageRoute, {
            from: getcompany_id,
            to: currentChat._id,
        });
       
        //console.log(currentChat._id);
        //console.log(response.data[0]);

        //console.log(response.data[0]['from_id']);
        // setMessages(response.data);
        //console.log(currentChat._id);

        // if (response.data[0]["from_id"]!== currentChat._id) {
        //        setMessages('');
        //  }
         

            setMessages(response.data);
            setLoading(false);
     
        // Setgmessage(response.data[0]["from_id"]);
        // if (response.data[0] === "undefined") {
        //      console.log('undefined hitted');
        //        setMessages("");
        // } else {
        //     console.log("not undefined hitted");
        //     setMessages(response.data);
        // }
        // }
     

    }, [currentChat]);

    useEffect(() => {

          //console.log(getusername);

        const getCurrentChat = async () => {
            if (currentChat) {
                await getcompany_id;
                // await JSON.parse(
                //     localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
                // )._id;
            }
        };
        getCurrentChat();
    }, [currentChat]);
   

    socket.current.on("disconnect", (reason) => {
        
        // if (reason === "io server disconnect") {
            // the disconnection was initiated by the server, you need to reconnect manually
            //socket.connect();
        // }
        //console.log(reason);   
        // console.log(reason+" thats why Disconnected");
        //  socket.current.emit("add-user", getcompany_id);
         
        window.location.reload();
        
    });

    const handleSendMsg = async (msg) => {
        // const data = await JSON.parse(
        //     localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
        // );
         //console.log(currentChat._id);
         //console.log(getcompany_id);
        
         socket.current.emit("send-msg", {
            to: currentChat._id,
            //from: data._id,
            from: getcompany_id,
            msg,
          });

          socket.current.emit("notification", {
              to_user: currentChat._id,
              from_user: getcompany_id,
          }); 
   

        //$(".chat_" + currentChat._id).show();
        // console.log(socket.current.on("msg-notification"));
        //  if (socket.current) {
        //     socket.current.on("msg-notification", (msg) => {
        //      console.log(msg);
        //     });
        //  }
        //console.log(currentChat);

          if (split1 == "type=admin") {
            
              var user_types = "3";
         
            } else if (split1 == "type=user") {
             
              var user_types = "2";
          }
       

           await axios.post(sendMessageRoute, {
               //from: data._id,
               from: getcompany_id,
               to: currentChat._id,
               message: msg,
               //message_count: 0,
           
             }).then(response=>{
               
            
                const msgs = [...messages];
                msgs.push({ fromSelf: true, message: msg });
                setMessages(msgs);
                setLoading(false);
            
              }).catch(error => {
           
               console.error('Error fetching data:', error);
               setLoading(false);
           
             });

             
          //  const msgs = [...messages];
           
          //  msgs.push({ fromSelf: true, message: msg });
          //  setMessages(msgs);


          // scrollRef.current?.scrollIntoView({ behavior: "smooth" });
          // } catch (error) {
          //  console.error("Error fetching data:", error);
         //  } finally {
          //   setLoading(false); // Set loading to false regardless of success or error
          // } 
   

        };


     useEffect(() => {
        // const data = JSON.parse(
        //               localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
        //         );
        //console.log(responsemsg);
        // if (responsemsg === currentChat._id){
  
        if (socket.current) {
            socket.current.on("msg-recieve", (msg) => {
                 //console.log(msg);
              
                //console.log('message received');
                //console.log(msg);

                //console.log(currentChat._id);
                // const data = JSON.parse(
                //     localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
                // );
                //console.log(data._id);
                //console.log(currentChat);

                //audioRef.current.play();

                //showNotification(msg);

                //setArrivalMessage({ fromSelf: false, message: msg });

                setArrivalMessage({ fromSelf: false, message: msg.msg });

                // Register the event listener for new notifications
                // Replace this with your actual notification handling logic
               
            });
        }
   
        //}
    }, []);
     
    
    // const showNotification = (message) => {
    //     if (Notification.permission === "granted") {
    //         const notification = new Notification("New Message", {
    //             body: message,
    //         });

    //         notification.onclick = () => {
    //             // Handle notification click, e.g., open chat window
    //         };
    //     }
    // };
   


    //  const playAudio = () => {
    //      //audio.src = "https://res.cloudinary.com/Apple_Notification.mp3"; // Replace with your audio file URL
    //      audio.src = audiofile; // Replace with your audio file URL
    //      //audio.src = m.path; // Replace with your audio file URL
    //      audio.load();
    //      audio.play();
    //  };

    useEffect(() => {
         
          arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
   
      }, [arrivalMessage]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    return (
        <Container>
            <div className="chat-header">
                <div className="user-details">
                    <div className="avatar">
                        { /* <img
                            src={`data:image/svg+xml;base64,${currentChat.avatarImage}`}
                            alt=""
                          /> */}
                    </div>
                    <div className="username">
                        <h3>{currentChat.username}</h3>
                    </div>
                </div>
                {/* <Logout /> */}
            </div>

             {/* { loading_chat ? (
                <div className="loading">Loading...</div>
            ) : ( */}
                
                <div className="chat-messages">
                    {messages.map((message) => {
                        //console.log(message.message);

                        return (
                            <div ref={scrollRef} key={uuidv4()}>
                                <div
                                    className={`message ${
                                        message.fromSelf ? "sended" : "recieved"
                                    }`}
                                >
                                    <div className="content ">
                                        <p>{message.message}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

            {/* )} */}

            <ChatInput
                handleSendMsg={handleSendMsg}
                currentChat={currentChat._id}
                getcompany_id={getcompany_id}
            />
        </Container>
    );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 10% 80% 10%;
  gap: 0.1rem;
  overflow: hidden;
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-rows: 15% 70% 15%;
  }
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    .user-details {
      display: flex;
      align-items: center;
      gap: 1rem;
      .avatar {
        img {
          height: 3rem;
        }
      }
      .username {
        h3 {
          color: white;
        }
      }
    }
  }
  .chat-messages {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .message {
      display: flex;
      align-items: center;
      .content {
        max-width: 40%;
        overflow-wrap: break-word;
        padding: 1rem;
        font-size: 1.1rem;
        border-radius: 1rem;
        color: #d1d1d1;
        @media screen and (min-width: 720px) and (max-width: 1080px) {
          max-width: 70%;
        }
      }
    }
    .sended {
      justify-content: flex-end;
      .content {
        background-color: #4f04ff21;
      }
    }
    .recieved {
      justify-content: flex-start;
      .content {
        background-color: #9900ff20;
      }
    }
  }
`;
