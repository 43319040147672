import { ADD_TO_CART } from "../constants";
import { DELETE_TO_CART } from "../constants";
import { INCREMENT } from "../constants";
import { DECREMENT } from "../constants";
import { GET_COMPANY_ID } from "../constants";

// const initialState = {
//     cartData: [],
// };

const initialState={
    counter: 0
};

//const initialState=0;

export default function getcompany_id(state = initialState, action) {
    switch (action.type) {
        // case ADD_TO_CART:
        //     //console.warn("reducer", action);
        //     return [...state, { cartData: action.data }];
        //     break;

        // case DELETE_TO_CART:
        //     return {
        //         ...state,
        //         cartData: action.data,
        //     };

        case GET_COMPANY_ID:
            return {
                ...state,
                counter: action.data,
            };

        // case DECREMENT:
        //   return {
        //       ...state,
        //       counter: action.data,
        //   }

        default:
            return state;
    }
}

