import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import styled from "styled-components";

import {
    allUsersRoute,
    host,
    get_company_id,
    company_users,
    get_company_users,
    get_all_user_chat,
    create_users,
    company_id_get,
    delete_chat_id,
    get_current_tab,
} from "../utils/APIRoutes";

//import ChatContainer from "../components/ChatContainer";
import UserChatContainer from "../components/UserChatContainer";
import Contacts from "../components/Contacts";
import Welcome from "../components/Welcome";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { get_companyid } from "../Services/Actions/action";
import $ from "jquery";

//import { getToken, messaging } from ".././firebase"; // Adjust the path accordingly

export default function Chatclient() {
    const { id } = useParams();
    const navigate = useNavigate();
    const socket = useRef();
    const [contacts, setContacts] = useState([]);
    const [currentChat, setCurrentChat] = useState(undefined);
    const [currentUser, setCurrentUser] = useState(undefined);
    const [getcompany_id, Setcompany_id] = useState(undefined);
    const [getusername, Setusername] = useState(undefined);
    const [getusertype, Setusertype] = useState(undefined);
    const [msg_notification, Setmsg_notification] = useState(null);
    const [msg_counter, Setmessagecounter] = useState(0);
    const [companyidset, Setcompanyid] = useState(null);
    const [getcurrent_id, Setcurrent_id] = useState(null);
    const [loading, setLoading] = useState(true);
    const [childData, setChildData] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    //const [isUserOnline, setIsUserOnline] = useState(false);

    const [users, setUsers] = useState([]);

    const url = window.location.pathname;
    const segment = url.split("/");
    const url2 = window.location.href;

    const split_seg = url2.split("?");

    const split0 = split_seg[0];
    const split1 = split_seg[1];

    const searchParams = new URLSearchParams(window.location.search);
    const type = searchParams.get("type");

    //const search_type_user = split1.search("type=user");
    //const search_type_company = split1.search("type=admin");

    const company_section = segment[1];
    const users_name = segment[2];

    const dispatch = useDispatch();
    const user_type = "";
    var user_types = "";
    // user type 3 administrator
    //  user type 2 user or client
    // user type 1 company

    //const getcompanyid = useSelector((state) => state.getcompany_id).counter;

     useEffect(async () => {
        
            // const getFCMToken = async () => {
            //     try {
            //         // Request notification permission
            //         await messaging.requestPermission();

            //         // Get the FCM token
            //         const token = await messaging.getToken();
            //         console.log("FCM Token:", token);

            //         // Send the token to your server for storage
            //         // Use an API endpoint on your Node.js server to store the tokens
            //         // Example:
            //         // fetch('http://localhost:3001/register-fcm-token', {
            //         //   method: 'POST',
            //         //   headers: {
            //         //     'Content-Type': 'application/json',
            //         //   },
            //         //   body: JSON.stringify({
            //         //     userId: 'USER_ID', // Replace with your user ID logic
            //         //     fcmToken: token,
            //         //   }),
            //         // });

                    
            //     } catch (error) {
            //         console.error(
            //             "Error getting notification permission or FCM token:",
            //             error
            //         );
            //     }
            // };

            //getFCMToken();

        
        //console.log(split1);

        //console.log(split_seg);

        //  if (company_section == "" && user_type == "") {
        //      navigate("/expire");
        //  } else if (company_section == "" || user_type == "") {
        //      navigate("/expire");
        //  }

        //const data = await axios.get(`${get_company_id}/${company_section}`);

        //console.log(split_seg[1]);

        //   const create_users = await axios.post(create_users, {
        //       company_id: companies_id,
        //       username: users_name,
        //     });

        //  console.log(company_section);
        //  console.log(split1);
        //  console.log(users_name);

        // if (search_type_user<=0){
        // console.log(split1);
        //console.log("Admin");

        

        socket.current = io(host, {
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: Infinity,
        });


        if (type == "user") {
                //console.log('here');

            var create_user = await axios.get(create_users, {
                params: {
                    company_section: company_section,
                    user_type: 2,
                    users_name: users_name,
                },
            });

               socket.current.emit("new-user-adds", 1);
        
                } else if (type == "admin") {
             
                //console.log("here1");
              var create_user = await axios.get(create_users, {
                params: {
                    company_section: company_section,
                    user_type: 3,
                    users_name: users_name,
                },
            });

            socket.current.emit("new-user-adds", 1);

        }


        //console.log(create_user.data);
        // if (search_type_company <= 0) {
        //     console.log("User");
        //     const create_user = await axios.post(create_users, {
        //         company_section: company_section,
        //         user_type: 2,
        //         users_name: users_name,
        //     });
        // }
        //console.log(create_user);

        //  if (search_type_company<0){
        //       console.log("User");
        //  }
        // console.log(create_user.data.get_user_id);


        if (create_user.data.get_user_id == "") {
            window.location.reload();
        }

        if (type == "admin") {
            Setusertype("3");
            var user_types = "3";
        } else if (type == "user") {
            Setusertype("2");
            var user_types = "2";
        }

          //console.log(get_company_id);
          // console.log(company_section);

            try {
            
             const data = await axios.get(`${get_company_id}`, {
                 params: {
                     company_name: company_section,
                 },
             });

             const companies_id = data.data[0]._id;

              //console.log(companies_id);

             const data1 = await axios.post(get_company_users, {
                 company_id: companies_id,
                 username: users_name,
                 user_type: user_types,
             });

             const user_id = data1.data[0];
             //console.log(user_id);

             Setcompany_id(companies_id);
             Setusername(data1.data[0]["username"]);
             setCurrentUser(user_id);
       
            } catch (error) {
             console.error("Error fetching data:", error);
            } finally {
              setLoading(false); // Set loading to false regardless of success or error
            }

        //console.log(getusername);

        //console.log(company_id);
        //setCurrentUser(company_id);
        //console.log(segment[1]);

        // if (!localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)) {
        //   navigate("/login");
        // } else {
        //   setCurrentUser(
        //     await JSON.parse(
        //       localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
        //     )
        //   );
        // }
        //console.log(currentChat);

        socket.current = io(host, {
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: Infinity,
        });

        if (socket.current) {
            socket.current.on("send_notification", (msg) => {
                //console.log(getcurrent_id);
                //console.log(msg);

                //delete_chat_count(getcurrent_id,msg);
                if (getcurrent_id !== null && msg !== null)
                    fetch(
                        `${delete_chat_id}` +
                            "?" +
                            new URLSearchParams({
                                sender_id: getcurrent_id,
                                receiver_id: msg,
                            }),
                        
                        {
                            method: "GET",
                            headers: {
                                "Content-type":
                                    "application/json; charset=utf-8",
                                "Access-Control-Allow-Origin": "*",
                                //Authorization: `Bearer ${window.token}`,
                            },
                        }

                    ).then((result) => {
                        result.json().then((resp) => {
                            //console.log('ok-delete');
                            //var company_id = resp[0]._id;
                        });
                    });

                $(".chat_" + msg).show();

                    get_user_net();

                //console.log('super');
                //console.log(getcompany_id);

                //get_chat_selected();
                //Setmsg_notification(msg);

            });
        }
     
        if (socket.current) {
            socket.current.on("send-message-delete", (msg) => {
                //console.log("hitted2");
                //window.location.reload();
                //get_user_net();
                //Setmsg_notification(msg);
            });
        }

        if (socket.current) {
            socket.current.on("user_added", (msg) => {
                get_user_net();
            });
        }

        if (socket.current) {
            socket.current.on("userStatus", (msg) => {
                //console.log(msg);
                get_user_net();
                //setIsUserOnline(false);
                //console.log("disconnected");
            });
        }

        // console.log(create_user.data.new_user_added);
        // if (create_user.data.new_user_added!=="") {
    }, []);


    function get_chat_selected(user_id) {
        //console.log('hut');
        //console.log(user_id);

        fetch(
            `${get_current_tab}` +
                "?" +
                new URLSearchParams({
                    sender_id: user_id,
                }),
                  {
                   method: "GET",
                   headers: {
                     "Content-type": "application/json; charset=utf-8",
                     "Access-Control-Allow-Origin": "*",
                    //Authorization: `Bearer ${window.token}`,
                },
            }
        ).then((result) => {
            result.json().then((resp) => {
                //console.log("selected");
                //if (resp !== "not-found") {
                //console.log(resp);
                
                $(".contact_class_" + resp).click();
                $(".contact_class_" + resp).addClass("selected");


                //$(".chat_" + resp).show();
                //}

                //console.log('ok-delete');
                //var company_id = resp[0]._id;
            });
        });
    }

    
    useEffect(() => {
       
          if (currentUser) {
               socket.current = io(host, {
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
                reconnectionAttempts: Infinity,
             });

            // Emit an event to request the list of users from the server
            //socket.current.emit("getOnlineUsers", currentUser._id);

            // Listen for the list of users from the server
            socket.current.on("onlineUsers", (onlineUsers) => {
                //console.log(onlineUsers);
                //setUsers(onlineUsers);
            });

            socket.current.emit("add-user", currentUser._id);
            //setIsUserOnline(true);

            //socket.current.emit("add-user", company_id);
            //socket.current.emit("current_users", currentUser._id);

             if (socket.current) {
                  socket.current.on("disconnect", (msg) => {
                    socket.current = io(host, {
                        reconnection: true,
                        reconnectionDelay: 1000,
                        reconnectionDelayMax: 5000,
                        reconnectionAttempts: Infinity,
                    });

                    socket.current.emit("add-user", currentUser._id);
                      console.log("disconnected");
                      //window.location.reload();
                      //setIsUserOnline(false);
                     //console.log("disconnected");
              });

            }
        }
      }, [currentUser]);


    useEffect(async () => {
        if (currentUser) {
            // if (currentUser.isAvatarImageSet) {
            //const data = await axios.get(`${allUsersRoute}/${currentUser._id}`);
            //console.log(currentUser._id);
            //console.log(currentUser);
            //console.log(getcompany_id);
            //console.log(get_all_user_chat);
            //console.log(currentUser._id);
            //console.log(get_all_user_chat);

            //console.log(getcompany_id);
            //console.log(currentUser._id);
            //console.log(getusertype);

            const data = await axios
                .get(
                    `${get_all_user_chat}/${getcompany_id}/${currentUser._id}`,
                    {
                        params: {
                            company_id: getcompany_id,
                            current_user: currentUser._id,
                            user_type: getusertype,
                        },
                    }
                )
                .then((res) => {
                    //console.log(res.data);
                    Setcompany_id(currentUser._id);
                    setContacts(res.data);
                })
                .catch((err) => console.log(err));



            //console.log(socket.current);
            // console.log(getcompany_id);
            //Setcompany_id(currentUser._id);
            //setContacts(data.data);

            //    if (socket.current) {
            //        socket.current.on("user_added", (msg) => {

            //            // console.log('norms'+msg);
            //            //window.location.reload();
            //         });
            //    }
            // } else {
            //   navigate("/setAvatar");
            // }

            setInterval(() => {
                // let online = isUserOnline();
                // console.log(online);
                // socket.current.disconnect(true);
            }, 5000);
        }
    }, [currentUser]);


    
    function get_user_net() {
  
  ///////////////////////////////////////////////////////Get company id//////////////////////////////////////////////////////
 
          fetch(
            `${get_company_id}` +
                "?" +
                new URLSearchParams({
                    company_name: company_section,
                }),
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=utf-8",
                    "Access-Control-Allow-Origin": "*",
                    //Authorization: `Bearer ${window.token}`,
                },
            }
        ).then((result) => {
            result.json().then((resp) => {
                var company_id = resp[0]._id;
                //console.log(resp[0]._id);
                if (type == "admin") {
                    Setusertype("3");
                    var user_types = "3";
                } else if (type == "user") {
                    Setusertype("2");
                    var user_types = "2";
                }

  ////////////////////////////////////////////////////////////////////get  user id//////////////////////////////////////////////////////

                const dataToSubmit = {
                    company_id: resp[0]._id,
                    username: users_name,
                    user_type: user_types,
                };

                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=utf-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(dataToSubmit),
                };

                fetch(`${get_company_users}`, requestOptions)
                    .then((response) => response.json())
                    .then(function (data) {
                        var get_user_id = data[0]._id;
                        //console.log(get_user_id);

 ///////////////////////////////////////////get all customers/////////////////////////////////////////////////////////////////////

                        fetch(
                            `${get_all_user_chat}/${company_id}/${get_user_id}` +
                                "?" +
                                new URLSearchParams({
                                    company_id: company_id,
                                    current_user: get_user_id,
                                    user_type: user_types,
                                }),
                              {
                                method: "GET",
                                headers: {
                                    "Content-type":
                                        "application/json; charset=utf-8",
                                    "Access-Control-Allow-Origin": "*",
                                    //Authorization: `Bearer ${window.token}`,
                                },
                              }
                              
                        ).then((result) => {
                            result.json().then((res) => {
                                //console.log(res.data);
                                setContacts(res);
                                get_chat_selected(get_user_id);

                            });
                        });
                    });
                });
            });
        }


     const handleChatChange = (chat) => {
        //console.log("current chat" + chat);
        setCurrentChat(chat);
        Setcurrent_id(chat._id);
        //console.log(chat);
        //delete_chat_count(chat);
    };

    const handleCounter = (msg_counter) => {
        Setmessagecounter(msg_counter + 1);
    };

    const handleChildData = (data) => {
          setChildData(data);
    };
    
    const handleClick = () => {
        setIsVisible(true);
    };

    
    return (
        <>
            <Container>
                {loading ? (
                    <div className="loading"></div>
                 ) : (
                 
                   <div className="container">
                        <span className="toggleicon" onClick={handleClick} ></span>
                        <Contacts
                            contacts={contacts}
                            changeChat={handleChatChange}
                            msg_notification={msg_notification}
                            msg_counter={msg_counter}
                            currentUser={currentUser}
                            delete_chat_id={delete_chat_id}
                            sendDataToParent={handleChildData}
                            setVisiblePass={isVisible}
                        />

                        { currentChat === undefined ? (
                            <Welcome />
                                 ) : (
                            <UserChatContainer
                                currentChat={currentChat}
                                socket={socket}
                                getcompany_id={getcompany_id}
                                currentUser={currentUser}
                                getusername={getusername}
                            />
                        )}
                    </div>
                )}
              </Container>
        </>
    );
  }


const Container = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    background-color: #131324;
    .container {
        height: 85vh;
        width: 85vw;
        background-color: #00000076;
        display: grid;
        grid-template-columns: 25% 75%;
        @media screen and (min-width: 720px) and (max-width: 1080px) {
            grid-template-columns: 35% 65%;
        }
    }
`;
