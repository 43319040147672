import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Robot from "../assets/robot.gif";

  //const url = window.location.pathname;
//  const url = window.location.href;

  // //const segment = url.split("/");
  // const company_section = segment[1];
  // const user_type = segment[2];
  // const new_url = new URL(url);
  // const searchParams = new URLSearchParams(url.search);
  // const name = searchParams.get("type");
  //const searchParams = new URLSearchParams(url.search);

   const searchParams = new URLSearchParams(window.location.search);
   const name = searchParams.get("name");

    const url = window.location.pathname;
    const segment = url.split("/");
    const company_section = segment[1];
    const user_type = segment[2];

export default function Welcome() {
    const [userName, setUserName] = useState("");

    useEffect(async () => {
        // setUserName(
        //   await JSON.parse(
        //     localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
        //   ).username
        // );
        //console.log(currentChat);
        if (name !== null) {
            setUserName(name);
        } else {
            setUserName(user_type);
        }

        // console.log(name);
    }, []);
    return (
        <Container>
            <img src={Robot} alt="" />

            <h1>
                Welcome, <span>{userName}!</span>
            </h1>
            <h3>Please select a chat to Start messaging.</h3>
        </Container>
    );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  img {
    height: 20rem;
  }
  span {
    color: #4e0eff;
  }
`;
