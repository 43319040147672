//export const host = "http://localhost:5000";
//export const host = "http://192.168.1.102:5000";
//export const host = "http://localhost:5000";
//export const host = "http://13.201.112.201:3001";
//export const host = "http://13.201.112.201:3001/";
//export const host = "https://chatapp.ablionit.com/node/";
//export const host = "https://chatapp.ablionit.com/";

  //export const host = "http://13.201.112.201:5000";
  export const host = "https://chatapp.ablionit.com/node";
  
 
 //export const host = "http://chatapp.ablionit.com:5000";
 export const loginRoute = `${host}/api/auth/login`;
 export const registerRoute = `${host}/api/auth/register`;
 export const front_register = `${host}/api/auth/front_register`;
 export const logoutRoute = `${host}/api/auth/logout`;
export const allUsersRoute = `${host}/api/auth/allusers`;
export const get_company_id = `${host}/api/auth/get_company_id`;
export const company_users = `${host}/api/auth/company_users`;
export const get_company_users = `${host}/api/auth/get_company_users`;
export const get_all_user_chat = `${host}/api/auth/get_all_user_chat`;
export const create_users = `${host}/api/auth/create_users`;
export const company_id_get = `${host}/api/auth/company_id_get`;
export const delete_chat_id = `${host}/api/auth/delete_chat_id`;
export const check_current_tabs = `${host}/api/auth/check_current_tabs`;
export const get_current_tab = `${host}/api/auth/get_current_tab`;
export const sendMessageRoute = `${host}/api/messages/addmsg`;
export const recieveMessageRoute = `${host}/api/messages/getmsg`;
export const setAvatarRoute = `${host}/api/auth/setavatar`;
export const notificationroute = `${host}/api/auth/send-push-notification`;
export const register_fcm_token = `${host}/api/auth/register-fcm-token`;


//export const  = `${host}/api/auth/send-push-notification`;
