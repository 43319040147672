import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { useSelector, useDispatch } from "react-redux";
import rootReducer from "./Services/Reducers/index";
import { Provider } from "react-redux";
//import eruda from "eruda";
//import { legacy_createStore as createStore } from "redux";
import store from "./Services/stote";



//import { createStore } from "redux";

//const root = ReactDOM.createRoot(document.getElementById("root"));
//const store = createStore(rootReducer);

//store.subscribe(() => console.log(store.getState()));
//eruda.init(); // Initialize Eruda only in development mode

ReactDOM.render(
    <React.StrictMode>
        
            <Provider store={store}>
                <App />
            </Provider>
      
    </React.StrictMode>,
    document.getElementById("root")
);
