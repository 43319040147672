import React, { useState, useEffect, useRef } from "react";
import { BsEmojiSmileFill } from "react-icons/bs";
import { IoMdSend } from "react-icons/io";
import styled from "styled-components";
import Picker from "emoji-picker-react";
import {
    allUsersRoute,
    host,
    get_company_id,
    company_users,
    get_company_users,
    get_all_user_chat,
    create_users,
    company_id_get,
    delete_chat_id,
    check_current_tabs,
    notificationroute,
    register_fcm_token,
} from "../utils/APIRoutes";
  
import { io } from "socket.io-client";
//import { getToken, messaging } from "./../firebase/firebase"; // Adjust the path accordingly

 export default function ChatInput({ handleSendMsg, currentChat, getcompany_id }) {
    const [msg, setMsg] = useState("");
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const socket = useRef();
     const [typingData, setTypingData] = useState({
         usersname: "",
         user_id: "",
         to_id: "",
     });

    const handleEmojiPickerhideShow = () => {
        setShowEmojiPicker(!showEmojiPicker);
    };
    let typingTimeout;
    const handleEmojiClick = (event, emojiObject) => {
        let message = msg;
        message += emojiObject.emoji;
        setMsg(message);
    };

    const sendChat = (event) => {
        event.preventDefault();
        if (msg.length > 0) {
            handleSendMsg(msg);
            setMsg("");
            //socket.current.emit("stopTyping", currentChat);
        }
    };

      
   socket.current = io(host, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
   });


    // const handleStoppedTyping = () => {
    //     setIsTyping(false);
    //     socket.current.emit("stopTyping", currentChat);
    // };
    const handleTyping = () => {
        // if (!isTyping) {
        //setIsTyping(true);
        var socket_arr = {
            0: currentChat,
            1: getcompany_id,
        }; 

           socket.current.emit("startTyping", socket_arr);

          //console.log("start typing" + currentChat);
        //socket.current.emit("message-delete", current_user);
        //}
 
    };

    useEffect(() => {

        let typingTimeout;

        socket.current.on("userTyping", (array) => {
            
            setTypingData(array);
            console.log("userid is" + getcompany_id);
            //console.log(typingData);
            //console.log(socket_id["to_id"]);

             setIsTyping(true);

            clearTimeout(typingTimeout);
              typingTimeout = setTimeout(() => {
                 setIsTyping(false);

                 //console.log("stopped typing" + array);

            }, 2000); // Set typing indicator to false after 2 seconds pause
        });

        return () => {
            clearTimeout(typingTimeout);
        };
        
    }, []);

    return (
   
      <Container>
            <div className="button-container">
                <div className="emoji">
                    <BsEmojiSmileFill onClick={handleEmojiPickerhideShow} />
                    {showEmojiPicker && (
                        <Picker onEmojiClick={handleEmojiClick} />
                    )}
                </div>
            </div>

            <form
                className="input-container"
                onSubmit={(event) => sendChat(event)} >
                {/* {typingData.to_id === getcompany_id &&
                typingData.to_id !== null ? (
                    <div className="get_termi">
                        {typingData.usersname} is typing to {typingData.to_id}{" "}
                        user id {typingData.user_id}
                    </div>
                ) : (
                    ""
                )} */}

                <input
                    type="text"
                    placeholder="type your message here"
                    onChange={(e) => {
                        setMsg(e.target.value);
                        //handleTyping();
                    }}
                    onFocus={(e) => {  }}
                    value={msg}
                />

                <button type="submit">
                    <IoMdSend />
                </button>
            </form>
        </Container>
    );
}

const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 5% 95%;
  background-color: #080420;
  padding: 0 2rem;
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    padding: 0 1rem;
    gap: 1rem;
  }
  .button-container {
    display: flex;
    align-items: center;
    color: white;
    gap: 1rem;
    .emoji {
      position: relative;
      svg {
        font-size: 1.5rem;
        color: #ffff00c8;
        cursor: pointer;
      }
      .emoji-picker-react {
        position: absolute;
        top: -350px;
        background-color: #080420;
        box-shadow: 0 5px 10px #9a86f3;
        border-color: #9a86f3;
        .emoji-scroll-wrapper::-webkit-scrollbar {
          background-color: #080420;
          width: 5px;
          &-thumb {
            background-color: #9a86f3;
          }
        }
        .emoji-categories {
          button {
            filter: contrast(0);
          }
        }
        .emoji-search {
          background-color: transparent;
          border-color: #9a86f3;
        }
        .emoji-group:before {
          background-color: #080420;
        }
      }
    }
  }
  .input-container {
    width: 100%;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    background-color: #ffffff34;
    input {
      width: 90%;
      height: 60%;
      background-color: transparent;
      color: white;
      border: none;
      padding-left: 1rem;
      font-size: 1.2rem;

      &::selection {
        background-color: #9a86f3;
      }
      &:focus {
        outline: none;
      }
    }
    button {
      padding: 0.3rem 2rem;
      border-radius: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #9a86f3;
      border: none;
      @media screen and (min-width: 720px) and (max-width: 1080px) {
        padding: 0.3rem 1rem;
        svg {
          font-size: 1rem;
        }
      }
      svg {
        font-size: 2rem;
        color: white;
      }
    }
  }
`;
