import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Logo from "../assets/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { io } from "socket.io-client";
import {
    allUsersRoute,
    host,
    get_company_id,
    company_users,
    get_company_users,
    get_all_user_chat,
    create_users,
    company_id_get,
    delete_chat_id,
    check_current_tabs,
   } from "../utils/APIRoutes";

  import $ from "jquery";
 

  const url = window.location.pathname;
  const segment = url.split("/");
  const company_section = segment[1];
  const user_type = segment[2];


   const searchParams = new URLSearchParams(window.location.search);
   const room = searchParams.get("room");

  export default function Contacts({
    contacts,
    changeChat,
    msg_notification,
    msg_counter,
    currentUser,
    delete_chat_id,
    sendDataToParent,
    setVisiblePass

  }) {
   
    const [currentUserName, setCurrentUserName] = useState(undefined);
    const [currentUserImage, setCurrentUserImage] = useState(undefined);
    const [currentSelected, setCurrentSelected] = useState(undefined);
    const [currentchat_id, Setcurrentchat_id] = useState(undefined);
    const [get_user_id, Setuser_id] = useState(undefined);
    const socket = useRef();
    const [onlineUsers, setOnlineUsers] = useState(new Map()); // Initialize with a Map
    const [isTyping, setIsTyping] = useState(false);

    const searchParams = new URLSearchParams(window.location.search);
    const name = searchParams.get("name");

    const url = window.location.pathname;
    const segment = url.split("/");
    const company_section = segment[1];
    const user_type = segment[2];
    const sideRef = useRef();

    
    useEffect(async () => {
        // const data = await JSON.parse(
        //   localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
        // );
        //setCurrentUserName(data.username);
        //setCurrentUserImage(data.avatarImage);
        //console.log(getcompany_ids);

        setCurrentUserImage(company_section);

        if (name !== null) {
            setCurrentUserName(name);
        } else {
            setCurrentUserName(user_type);
        }

        if (socket.current) {
            socket.current.on("userStatus", (status) => {
                setOnlineUsers(new Map(Object.entries(status))); // Convert status object to a Map
            });
            //console.log(onlineUsers);
        }

        //    if(setVisiblePass){
        //      sideRef.current.classList.remove("hide");
        //    }else{
        //       sideRef.current.classList.add("hide");
        //    } 
        
         $(".toggleicon").on("click", function (e) {
             e.preventDefault();
             // Remove the class 'your-class' from the element(s)
             $(".sc-dcJsrY").removeClass("hide");
             
         });

    }, []);

    const isUserOnline = (userId) => onlineUsers.has(userId); // Check if user is in the Map

    const getUserOnlineStatus = (userId, callback) => {
        const isOnline = isUserOnline(userId);
        callback(isOnline);
    };

    //    onSortByPlatformAsc = () => {
    //     // var sortByPlatform = listGames.sort((a, b) =>
    //     //   a.platform.localeCompare(b.platform)
    //     // );
    // };
    // function
    // const numAscending = [...contacts].sort((a, b) => a._id-b._id);
    // console.log(numAscending);

    
    function delete_chat_count(user_id, current_user) {
        //console.log(user_id);
        //console.log(current_user);
        //console.log(delete_chat_id);

        socket.current = io(host, {
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: Infinity,
        });

        socket.current.emit("message-delete", current_user);

        //   socket.current.emit("message-delete", current_user);
        //   socket.current.emit("message-delete", current_user);

        //console.log('hi');
        console.log(user_id);
        console.log(current_user);

        $(".chat_" + current_user).hide();

        fetch(
            `${delete_chat_id}` +
                "?" +
                new URLSearchParams({
                    sender_id: user_id,
                    receiver_id: current_user,
                }),
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-type": "application/json; charset=utf-8",
                    "Access-Control-Allow-Origin": "*",
                    //Authorization: `Bearer ${window.token}`,
                },
            }
        ).then((result) => {
            result.json().then((resp) => {
                //console.log('ok-delete');
                //var company_id = resp[0]._id;
            });
        });
    }


    async function check_current_tab(user_id, current_user, current_username) {
        //console.log(check_current_tabs);
        await fetch(
            `${check_current_tabs}` +
                "?" +
                new URLSearchParams({
                    sender_id: user_id,
                    receiver_id: current_user,
                    current_index: current_username,
                }),
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-type": "application/json; charset=utf-8",
                    "Access-Control-Allow-Origin": "*",
                    //Authorization: `Bearer ${window.token}`,
                },
            }
        ).then((result) => {
            result.json().then((resp) => {
                //console.log('ok-delete');
                //var company_id = resp[0]._id;
            });
        });
    }


    const changeCurrentChat = (index, contact) => {
        // console.log(currentUser._id);
        // console.log(contact._id);

        var user_id = currentUser._id;
        var current_user = contact._id;
        var current_username = contact.username;

        //console.log('hi'+user_id);
        //console.log('hel'+current_user);

        setCurrentSelected(index);
        changeChat(contact);

        Setcurrentchat_id(contact._id);
        Setuser_id(user_id);

        delete_chat_count(user_id, current_user);
        check_current_tab(user_id, current_user, current_username);
    };


    function checkclick() {
        sideRef.current.classList.add("hide");
        console.log("clicke");
    }
   
    return (
        <>
            {currentUserImage && currentUserImage && (
                <Container className="sideBar" ref={sideRef}>
                    <div className="brand">
                        {/* <img src={Logo} alt="logo" /> */}
                        {room !== null ? <h3> {room} </h3> : <h3>Chat</h3>}
                    </div>
                    <div className="contacts">
                        { contacts.map((contact, index) => {
                            return (
                                <div
                                    key={contact._id}
                                    className={
                                        `contact  ${
                                            index === currentSelected
                                                ? "selected"
                                                : ""
                                        } contact_class_` + contact._id
                                    }
                                    onClick={() => {
                                        changeCurrentChat(index, contact);
                                        // sendDataToParent('clicked');
                                        checkclick();
                                    }}
                                >
                                    <div className="user_type">
                                        {contact.user_type == "3" ? (
                                            <div className="admin_class">
                                                <div>Admin</div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    <div className="avatar">
                                        {/* <img
                                 src={`data:image/svg+xml;base64,${contact.avatarImage}`}
                                     alt=""
                                         /> */}
                                    </div>
                                    <div className="username">
                                        <h3>{contact.username} </h3>

                                        {contact.status == "1" ? (
                                            <div className="online"></div>
                                        ) : (
                                            <div className="offline"></div>
                                        )}
                                    </div>
                                    <div
                                        className={
                                            "chat_notofication  chat_" +
                                            contact._id
                                        }
                                    >
                                        {contact.message_count !== undefined ? (
                                            <div
                                                className={
                                                    "msg_noti  masg" +
                                                    contact._id
                                                }
                                            >
                                                <div
                                                    className={
                                                        "msg_coun cou" +
                                                        contact._id
                                                    }
                                                >
                                                    {
                                                        contact.message_count[
                                                            "count"
                                                        ]
                                                    }
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {/* {msg_notification === contact._id ? (
                                            <div className="msg_noti">
                                                <div className="msg_coun">
                                                    {msg_counter}
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )} */}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="current-user">
                        <div className="avatar">
                            {/* <img
                src={`data:image/svg+xml;base64,${currentUserImage}`}
                alt="avatar"
              /> */}
                        </div>
                        <div className="username">
                            <h2>{currentUserName}</h2>
                        </div>
                    </div>
                </Container>
            )}
        </>
    );
}
const Container = styled.div`
  display: grid;
  grid-template-rows: 10% 75% 15%;
  overflow: hidden;
  background-color: #080420;
  .brand {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    img {
      height: 2rem;
    }
    h3 {
      color: white;
      text-transform: uppercase;
    }
  }
  .contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    gap: 0.8rem;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .contact {
      background-color: #ffffff34;
      min-height: 5rem;
      cursor: pointer;
      width: 90%;
      border-radius: 0.2rem;
      padding: 0.4rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      transition: 0.5s ease-in-out;
      .avatar {
        img {
          height: 3rem;
        }
      }
      .username {
        h3 {
          color: white;
        }
      }
    }
    .selected {
      background-color: #9a86f3;
    }
  }

  .current-user {
    background-color: #0d0d30;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    .avatar {
      img {
        height: 4rem;
        max-inline-size: 100%;
      }
    }
    .username {
      h2 {
        color: white;
      }
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      gap: 0.5rem;
      .username {
        h2 {
          font-size: 1rem;
        }
      }
    }
  }
`;
