import React from 'react'

const Expire = () => {
  return (
      <>
          <h2>Page has been expired</h2>
      </>
  );
}

export default Expire