import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import styled from "styled-components";
import {
    allUsersRoute,
    host,
    get_company_id,
    company_users,
} from "../utils/APIRoutes";
import ChatContainer from "../components/ChatContainer";
import Contacts from "../components/Contacts";
import Welcome from "../components/Welcome";
import { useParams } from "react-router-dom";
 import { useSelector, useDispatch } from "react-redux";
 import { get_companyid } from "../Services/Actions/action";

export default function Chat() {
  const { id } = useParams();
  const navigate = useNavigate();
  const socket = useRef();
  const [contacts, setContacts] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser,  setCurrentUser] = useState(undefined);
  const [getcompany_id, Setcompany_id] = useState(undefined);
  const [getusername, Setusername] = useState(undefined);
 


  const url = window.location.pathname;
  const segment = url.split("/");
  const company_section = segment[1];
  const user_type = segment[2];
    
  const dispatch = useDispatch();

  const getcompanyid = useSelector((state) => state.getcompany_id).counter;

    useEffect(async () => {
         

          //  if (company_section == "" && user_type == "") {
          //      navigate("/expire");
          //  } else if (company_section == "" || user_type == "") {
          //      navigate("/expire");
          //  }
              
             

              const data = await axios.get(
                   `${get_company_id}/${company_section}`
              );

        //console.log(get_company_id);
        //console.log(company_section);
        //console.log(user_type);

            const company_id = data.data[0];
       
            
           dispatch(get_companyid(company_id));
           setCurrentUser(company_id);
           //console.log(company_id.username);
          
           const usernames = company_id.username;
           Setusername(usernames);
       
        
           //console.log(company_id);
           //setCurrentUser(company_id);
           //console.log(segment[1]);

          // if (!localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)) {
          //   navigate("/login");
           // } else {
               //   setCurrentUser(
             //     await JSON.parse(
           //       localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
           //     )
          //   );
               // }

     }, []);


  useEffect(() => {
         //console.log(`${id}`);
       if (currentUser) {
          socket.current = io(host, {
           reconnection: true,
           reconnectionDelay: 1000,
           reconnectionDelayMax: 5000,
           reconnectionAttempts: Infinity,
        });
    
             //console.log(currentUser);
            socket.current.emit("add-user", currentUser._id);
   
          //socket.current.emit("add-user", company_id);
          //socket.current.emit("current_users", currentUser._id);

    }
   }, [currentUser]);


    useEffect(async () => {
      
        if (currentUser) {

              // console.log(getusername);
             // if (currentUser.isAvatarImageSet) {
            //const data = await axios.get(`${allUsersRoute}/${currentUser._id}`);
            //console.log(currentUser._id);
           //console.log(currentUser);
             
             //console.log(currentUser);
             const data = await axios.get(`${company_users}/${currentUser._id}`);
  
             Setcompany_id(currentUser._id);
             setContacts(data.data);
             //console.log(currentUser.id);
             //console.log(getcompany_id);
             Setusername(currentUser.username);
             //console.log(getusername);

             // } else {
           //   navigate("/setAvatar");
          // }
    }
  }, [currentUser]);


  const handleChatChange = (chat) => {
        setCurrentChat(chat);
  };


   return (
        <>
           <Container>
               <div className="container">
                   <Contacts
                       contacts={contacts}
                       changeChat={handleChatChange}
                  />

                   {currentChat === undefined ? (
                       <Welcome />
                   ) : (
                       <ChatContainer
                           currentChat={currentChat}
                           socket={socket}
                           getcompany_id={getcompany_id}
                       />
                   )}
               </div>
            </Container>
        </>
      );
   }

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #131324;
  .container {
    height: 85vh;
    width: 85vw;
    background-color: #00000076;
    display: grid;
    grid-template-columns: 25% 75%;
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      grid-template-columns: 35% 65%;
    }
  }
`;
